<template>
    <div>
        <v-container class="p-relative">
            <v-row>
                <v-col>
                    <v-row>
                        <AdminHeader :registryUrl="'dictionaries'" :registryTitle="$t('global:dictionaries')" />
                    </v-row>
                    <v-row>
                        <v-tabs v-model="tab" :right="true" class="dictionary">
                            <v-tab v-for="item in tabs" :key="item.name">{{
                                    $t(`global:${item.name}`)
                            }}</v-tab>
                            <v-tab-item v-for="item in tabs" :key="item.name" class="pa-2">
                                <div class="inside-tab--container">
                                    <div class="dict--items--container">
                                        <div cols="3" v-for="dict in getDictionariesByTab(item.name)" :key="dict._id">
                                            <Dictionary :itemsDictionaries="dict.entries" :dictionaryName="dict.name"
                                                :dictionaryId="dict._id" class="dictionary__box"
                                                @edit-entry="editEntry($event, dict)"
                                                @add-entry="addEntry($event, dict)"
                                                @remove-entry="removeEntry($event, dict)">
                                            </Dictionary>
                                        </div>
                                    </div>
                                </div>

                            </v-tab-item>
                        </v-tabs>
                    </v-row>



                </v-col>
            </v-row>

            <Modal :title="$t('global:addition')" :open="open" :height="'300'" :width="'500'" v-on:close="open = false">
                <!-- <AEContent slot="AEContent" /> -->
                <template slot="AEContent">
                    <v-stepper v-model="stepper" class="elevation-0">
                        <v-stepper-header>
                            <v-divider></v-divider>
                            <v-stepper-step step="1" editable>
                                {{ $t('dictionaries:newDictionary') }}
                            </v-stepper-step>
                            <v-divider></v-divider>
                        </v-stepper-header>

                        <v-stepper-items class="cursor-pointer">
                            <v-stepper-content step="1">
                                <v-col cols="12">
                                    <v-text-field data-cy="dictionaryName" :label="
                                        $t(
                                            'dictionaries:dictionaryName'
                                        )
                                    " v-model="dictName"></v-text-field>
                                </v-col>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </template>
                <template slot="buttons">
                    <div>
                        <v-btn @click="createDict()" class="buttons buttons--add">
                            {{ $t('global:save') }}
                        </v-btn>
                    </div>
                </template>
            </Modal>

        </v-container>
        <div v-if="getProfileDetails.isSuperAdmin" class="d-flex mr-2 mb-1 justify-end buttons--action">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn data-cy="addEntry" fab dark v-on="on" small @click="open = true"
                        class="buttons--add margin--1">
                        <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span> {{ $t('dictionaries:addDictionary') }} </span>
            </v-tooltip>
        </div>
    </div>
</template>
<script>
// import DictionariesUsers from './../../../components/Admin/Dictionaries/Users/Users'
import store from '../../../store/index'
import Modal from '../../../components/Global/Modal/Modal'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    data: () => ({
        open: false,
        dictName: '',
        stepper: 1,
        dictField: '',
        tab: 0,
        tabs: [{ name: 'dictionaries' }, { name: 'service' }, { name: 'cars' }, { name: 'offers' }, { name: 'contracts' }, { name: 'leasings' }, { name: 'operation-lock' }, { name: 'rent-contracts' }, { name: 'newsletter' }, { name: 'crm' }],
        alphabetSort: true
    }),
    i18nOptions: { namespaces: ['global', 'dictionaries'] },

    computed: {
        ...mapGetters([
            'getDictionaries',
            'getProfileDetails',
            // 'checkUserPermission',
        ]),
    },
    components: {
        Modal,
    },
    methods: {
        ...mapActions(['updateDictionary', 'createDictionary']),
        async editEntry(event, dict) {
            for (let entry of dict.entries) {
                if (entry[dict.field] == event.editingField[dict.field]) {
                    entry[dict.field] = event.field
                }
            }
            await this.updateDictionary({ id: dict._id, body: dict })
        },
        async addEntry(event, dict) {
            dict.entries.unshift(event)
            dict.entries = [...new Set(dict.entries)]
            await this.updateDictionary({
                id: dict._id,
                body: dict,
            })

        },
        async removeEntry(event, dict) {
            dict.entries = dict.entries.filter(
                entry => entry != event.removingEntry
            )
            await this.updateDictionary({ id: dict._id, body: dict })

        },
        async createDict() {
            await this.createDictionary({
                name: this.dictName,
                tab: this.tabs.map(tab => tab.name)[this.tab],
            })
            this.open = false
            this.dictName = ''
            this.alphabetSort = false;

        },
        getDictionariesByTab(tabName) {

            const dictionaries = this.getDictionaries.filter(dict => dict.tab === tabName);
        
            if (this.alphabetSort) {
                return dictionaries.sort(function (a, b) {
                    var textA = a.name.toUpperCase();
                    var textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            } else {

                const sorted = dictionaries.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt)
                })

                return sorted;
            }


        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchDictionaries', { next })
    },
}
</script>
<style lang="scss" scoped>
.dict--items--container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
}

.inside-tab--container {
    overflow-y: auto;
    max-height: 72vh;


}
</style>